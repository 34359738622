import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./screens/homepage/Homepage";
import PageNotFound from "./screens/pageNotFound/PageNotFound";
import JobListPage from "./screens/joblist/JobListPage";
import ScrollToTop from "./screens/globalComponents/ScrollToTop";
import { Toaster } from "react-hot-toast";
import JobDescription from "./screens/jobDescription/JobDescription";
import Legal from "./screens/legal/Legal";
import PrivacyPolicy from "./screens/privacyPolicy/PrivacyPolicy";
import ServiceAgreement from "./screens/serviceAgreement/ServiceAgreement";
import TermsConditions from "./screens/termsConditions/TermsConditions";
import AboutUs from "./screens/aboutUs/AboutUs";
import ContactUs from "./screens/contactUs/ContactUs";
import ConfirmModal, { confirmModalRef } from "./screens/globalComponents/ConfirmModal";
import Blogs from "./screens/blogs/Blogs";
import BlogList from "./screens/blogList/BlogList";
import FaqList from "./screens/faqList/FaqList";

function App() {
  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <ConfirmModal ref={confirmModalRef} />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/joblist" element={<JobListPage />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/job-desc/:id" element={<JobDescription />} />
          <Route path="/blogs/:id" element={<Blogs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/service-agreement" element={<ServiceAgreement />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/bloglist" element={<BlogList />} />
          <Route path="/faqs" element={<FaqList />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
