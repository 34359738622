import React, { useEffect, useState } from "react";
import Navbar from "../homepage/components/Navbar";
import { LoadingOutlined } from "@ant-design/icons";
import Footer from "../homepage/components/Footer";
import DescHero from "./components/DescHero";
import DescCard from "./components/DescCard";
import DescContent from "./components/DescContent";
import { useLocation } from "react-router-dom";
import { getJobList, updateJobViews } from "../../helpers/JobApi";
import { Spin } from "antd";
import axios from "axios";
import { Helmet } from "react-helmet";
import countryWithFlags from "../../utils/countryWithFlags.json";
import { API_URL, APP_JOB_LOGO_URL } from "../../helpers/AppApiUrls";

const JobDescription = () => {
  const location = useLocation();
  const [jobId, setJobId] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const jobIdRaw = location.pathname.split("/")[2];
    setJobId(jobIdRaw);
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const ip = response.data.ip;
        await updateJobViews({ jobId: jobIdRaw, ip });
      } catch (error) {
        console.error("Error fetching", error);
      }
    };
    fetchIP();
  }, [location]);

  const fetchJobDetailsFunc = async () => {
    try {
      setLoading(true);
      const fetchJobDetailsResult = await getJobList({ id: jobId });
      const skills = fetchJobDetailsResult[0]?.skills?.split(",,");
      const benefits = fetchJobDetailsResult[0]?.benefits?.split(",,");
      const responsibilities =
        fetchJobDetailsResult[0]?.responsibilities?.split(",,");
      setJobDetails({
        ...fetchJobDetailsResult[0],
        skills,
        benefits,
        responsibilities,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (jobId) fetchJobDetailsFunc();
    // eslint-disable-next-line
  }, [jobId]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Description - Dubai Job Finder</title>
        <link
          rel="canonical"
          href={`https://dubaijobfinder.net/job-desc/${jobId}`}
        />
        <meta
          name="description"
          content={`Explore this exciting opportunity for a ${jobDetails?.job_name} at ${jobDetails?.company_name} in ${jobDetails?.job_location}. Dive into a role that promises a dynamic work environment, competitive salary, and excellent growth prospects. Learn more about the qualifications, responsibilities, and how to apply. Join us and advance your career at a forward-thinking company committed to innovation and excellence.`}
        />
        <meta
          name="keywords"
          content="Dubai jobs, Job openings in Dubai, Dubai job vacancies, Dubai employment opportunities, Jobs in Dubai, Dubai careers, Dubai job search, Dubai job listings, Dubai job portal, Dubai job site, Dubai job board, Hiring in Dubai, Work in Dubai, Dubai professional jobs, Dubai job market, Dubai job fair, Dubai job recruitment, Dubai job sectors, Dubai job industries, Dubai job opportunities"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content={jobDetails?.job_name} />
        <meta
          property="og:description"
          content={`Explore this exciting opportunity for a ${jobDetails?.job_name} at ${jobDetails?.company_name} in ${jobDetails?.job_location}. Dive into a role that promises a dynamic work environment, competitive salary, and excellent growth prospects. Learn more about the qualifications, responsibilities, and how to apply. Join us and advance your career at a forward-thinking company committed to innovation and excellence.`}
        />
        <meta
          property="og:url"
          content={`https://dubaijobfinder.net/job-desc/${jobId}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${API_URL}${APP_JOB_LOGO_URL}/${jobDetails?.company_logo_name}`}
        />
        <script type="application/ld+json">
          {`
            {
              "@context" : "https://schema.org/",
              "@type" : "JobPosting",
              "title" : "${jobDetails?.job_name}",
              "description" : "${jobDetails?.description?.replace(
                /<\/?[^>]+(>|$)/g,
                ""
              )}",
              "identifier": {
                "@type": "PropertyValue",
                "name": "${jobDetails?.company_name}",
                "value": "${jobId}"
              },
              "datePosted" : "${jobDetails?.created_at?.split("T")[0]}",
              "validThrough" : "${
                jobDetails?.created_at &&
                new Date(
                  new Date(jobDetails?.created_at?.split("T")[0])?.setFullYear(
                    new Date(
                      jobDetails?.created_at?.split("T")[0]
                    )?.getFullYear() + 1
                  )
                )
                  ?.toISOString()
                  ?.split("T")[0]
              }",
              "employmentType" : "${jobDetails?.job_type_name}",
              "hiringOrganization" : {
                "@type" : "Organization",
                "name" : "${jobDetails?.company_name}",
                "sameAs" : "https://dubaijobfinder.net/job-desc/${jobId}",
                "logo" : "${API_URL}${APP_JOB_LOGO_URL}/${
            jobDetails?.company_logo_name
          }"
              },
              "jobLocation": {
                "@type": "Place",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${
                    jobDetails?.location?.street || "501 - BMI Building"
                  }",
                  "addressLocality": "${
                    jobDetails?.location?.locality ||
                    "Near SharafDG Metro Station"
                  }",
                  "addressRegion": "${
                    jobDetails?.location?.region || "Bur-Dubai"
                  }",
                  "postalCode": "${
                    jobDetails?.location?.postal_code || "122002"
                  }",
                  "addressCountry": "${
                    countryWithFlags.find(
                      (element) => element.countryId === jobDetails?.countryId
                    )?.name
                  }"
                }
              },
              "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "${jobDetails?.currency || "AED"}",
                "value": {
                  "@type": "QuantitativeValue",
                  "value": ${jobDetails?.salary_range?.split("-")[0]},
                  "unitText": "PER_MONTH"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <Navbar />
      <Spin
        spinning={loading}
        fullscreen
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
      <div id="hero">
        <DescHero
          jobTitle={jobDetails?.job_name}
          jobDate={jobDetails?.created_at}
        />
      </div>
      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-32 sm:px-1 px-1 container mx-auto mt-6 mb-36">
        <div className="flex sm:flex-row flex-col justify-between gap-16">
          <div className="float-left">
            <DescContent
              overview={jobDetails?.overview}
              desc={jobDetails?.description}
              skills={jobDetails?.skills}
              benefits={jobDetails?.benefits}
              responsibilities={jobDetails?.responsibilities}
              extra={jobDetails?.extra}
            />
          </div>
          <div className="float-right">
            <DescCard
              companyName={jobDetails?.company_name}
              logo_name={jobDetails?.company_logo_name}
              expRange={jobDetails?.experience_range}
              amount={jobDetails?.salary_range}
              nationalityIds={jobDetails?.nationalityId}
              countryId={jobDetails?.countryId}
              jobType={jobDetails?.job_type_name}
              location={jobDetails?.job_location}
              gender={jobDetails?.gender}
              jobId={jobId}
            />
          </div>
        </div>
      </div>

      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default JobDescription;
