import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCmsContent } from "../../helpers/CmsApi";
import { Spin } from "antd";
import Navbar from "../homepage/components/Navbar";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const [cmsContent, setCmsContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const cmsContentFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const getCmsContentResult = await getCmsContent("privacy-policy");
      setCmsContent(getCmsContentResult?.content);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    cmsContentFunc();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/privacy-policy" />
        <meta name="description" content="Read our Privacy Policy to understand how Dubai Job Finder collects, uses, and protects your personal information. We are committed to ensuring the privacy and security of all users." />
        <meta name="keywords" content="Dubai Job Finder privacy, privacy policy, user data protection Dubai, personal information security Dubai, privacy terms Dubai, data privacy Dubai jobs, online privacy practices, secure job search Dubai, personal data usage Dubai" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Privacy Policy" />
        <meta property="og:description" content="Read our Privacy Policy to understand how Dubai Job Finder collects, uses, and protects your personal information. We are committed to ensuring the privacy and security of all users." />
        <meta property="og:url" content="https://dubaijobfinder.net/privacy-policy" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>

      <Navbar />
      <Spin spinning={loading} fullscreen />
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: cmsContent || "" }} className="editor"></div>;
      </div>
    </div>
  );
};

export default PrivacyPolicy;
