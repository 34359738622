import { Space } from "antd";
import React from "react";

const BlogContent = ({ content }) => {
  return (
    <div>
      <Space direction="vertical" className="gap-8">
        <div>
          <Space direction="vertical">
            <div className="editor" dangerouslySetInnerHTML={{ __html: content || "" }} />
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default BlogContent;
