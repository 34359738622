import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Userflow from "./components/Userflow";
import SubHero from "./components/SubHero";
import DemandingJobs from "./components/DemandingJobs";
import JobGuides from "./components/JobGuides";
import Footer from "./components/Footer";

import { NavLink } from "react-router-dom";
import { getJobList } from "../../helpers/JobApi";
import { Helmet } from "react-helmet";

const Homepage = () => {
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getJobListFunc = async () => {
    try {
      setLoading(true);
      const jobListResult = await getJobList();
      setJobList(jobListResult);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobListFunc();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/" />
        <meta name="description" content="Welcome to Dubai Job Finder, your gateway to exploring the best job opportunities in Dubai. Browse hundreds of listings, find career advice, and connect with top employers to enhance your professional journey in Dubai's dynamic job market." />
        <meta
          name="keywords"
          content="Dubai jobs, Job openings in Dubai, Dubai job vacancies, Dubai employment opportunities, Jobs in Dubai, Dubai careers, Dubai job search, Dubai job listings, Dubai job portal, Dubai job site, Dubai job board, Hiring in Dubai, Work in Dubai, Dubai professional jobs, Dubai job market, Dubai job fair, Dubai job recruitment, Dubai job sectors, Dubai job industries, Dubai job opportunities"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Home - Dubai Job Finder" />
        <meta property="og:description" content="Welcome to Dubai Job Finder, your gateway to exploring the best job opportunities in Dubai. Browse hundreds of listings, find career advice, and connect with top employers to enhance your professional journey in Dubai's dynamic job market." />
        <meta property="og:url" content="https://dubaijobfinder.net/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>

      <Navbar />
      <div id="home">
        <Hero />
      </div>

      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-32 sm:px-10 px-6 container mx-auto">
        <div id="how-it-works">
          <Userflow />
          <SubHero />
        </div>
        <div id="find-jobs">
          <div className="flex justify-between items-end mb-10">
            <div className="sm:text-6xl text-4xl mt-14 font-extrabold text-start">Most Demanding Jobs</div>
            <NavLink className="hidden md:flex underline underline-offset-4 text-primary" to={"/joblist"}>
              See All Jobs
            </NavLink>
          </div>
          <DemandingJobs jobList={jobList} loading={loading} />
          <NavLink className="flex md:hidden underline underline-offset-4 text-primary" to={"/joblist"}>
            See All Jobs
          </NavLink>
        </div>
        <div id="job-guides">
          <JobGuides />
        </div>
      </div>

      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default Homepage;
