import React, { useEffect, useState } from "react";
import BlogCard from "./components/BlogCard";
import { getBlogList } from "../../helpers/BlogApi";
import { API_URL, APP_BLOG_IMAGE_URL } from "../../helpers/AppApiUrls";
import Navbar from "../homepage/components/Navbar";
import { Spin } from "antd";
import BlogListHero from "./components/BlogListHero";
import { LoadingOutlined } from "@ant-design/icons";
import Footer from "../homepage/components/Footer";
import JobGuideCards from "../globalComponents/JobGuideCards";
import { Helmet } from "react-helmet";

const BlogList = () => {
  const [blogDetails, setBlogDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchJobDetailsFunc = async () => {
    try {
      setLoading(true);
      const fetchJobDetailsResult = await getBlogList();
      setBlogDetails(fetchJobDetailsResult);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobDetailsFunc();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog List - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/bloglist" />
        <meta name="description" content="Explore our blogs at Dubai Job Finder for the latest insights, tips, and news on navigating Dubai's job market. Stay informed with our expert advice and updates to enhance your career." />
        <meta name="keywords" content="Dubai Job Finder blogs, job search tips Dubai, career advice Dubai, employment news Dubai, Dubai job market insights, job finder blog, Dubai careers blog, professional advice Dubai, Dubai employment updates" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Blog List" />
        <meta property="og:description" content="Explore our blogs at Dubai Job Finder for the latest insights, tips, and news on navigating Dubai's job market. Stay informed with our expert advice and updates to enhance your career." />
        <meta property="og:url" content="https://dubaijobfinder.net/bloglist" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>

      <Navbar />
      <Spin
        spinning={loading}
        fullscreen
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
      <div>
        <BlogListHero />
      </div>
      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-32 sm:px-10 px-6 container mx-auto">
        <div className="bg-white w-full mx-auto text-center justify-center">
          <div className="w-full flex items-center mx-auto justify-center mt-8 sm:mb-32 mb-10">
            <div className="gap-6 grid sm:grid-cols-3 grid-cols-1 w-full">
              {/* {blogDetails?.map((element) => (
                <JobGuideCards imgSrc={`${API_URL}${APP_BLOG_IMAGE_URL}/${element?.blog_image_name}`} blogDate={element?.created_at} title={element?.title} blogId={element?.id} />
              ))} */}
              {blogDetails?.map((element) => (
                element.active === "true" && (
                  <JobGuideCards
                    key={element.id}
                    imgSrc={`${API_URL}${APP_BLOG_IMAGE_URL}/${element?.blog_image_name}`}
                    blogDate={element?.created_at}
                    title={element?.title}
                    blogId={element?.id}
                  />
                )
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BlogList;
