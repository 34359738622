module.exports = {
  // API_URL: "http://localhost:8000",
  API_URL: "https://api.dubaijobfinder.net",
  APP_JOB_BASE_URL: "/api/jobs",
  APP_USER_BASE_URL: "/api/user",
  APP_CMS_BASE_URL: "/api/cms",
  APP_BLOG_BASE_URL: "/api/blog",
  APP_FAQ_BASE_URL: "/api/faq",
  APP_ADMIN_BASE_URL: "/api/admin",
  APP_JOB_LOGO_URL: "/logo",
  APP_BLOG_IMAGE_URL: "/blog",
};
