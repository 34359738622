import React, { useState } from "react";
import logo from "../../../data/logo/PNG/Dubai Job finder logo final file_Logo white.png";
import { NavLink } from "react-router-dom";
import { subscribeToNewsletter } from "../../../helpers/UserApi";
import toast from "react-hot-toast";

const Footer = () => {
  const [newsletterInput, setNewsletterInput] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  // #0B0C18

  const handleNewsletterInput = (e) => {
    const inputEmail = e.target.value;
    setNewsletterInput(inputEmail);

    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(regex.test(inputEmail));
  };

  const handleNewsletterSubmit = async () => {
    let toastBox = toast;
    try {
      const subscribeToNewsletterResult = await subscribeToNewsletter({
        email: newsletterInput,
        newsletter: true,
      });
      setNewsletterInput("");
      toast.success(`Subscribed!`, {
        id: toastBox,
      });
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  return (
    <footer className="bg-dark text-center text-neutral-600 dark:text-neutral-200 lg:text-left">
      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-32 sm:px-10 px-6 container mx-auto">
        {/* <!-- Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. --> */}
        <div className="mx-6 py-10 md:text-left flex flex-col justify-between text-start">
          <div className="xl:flex xl:flex-row xl:justify-between text-start grid-1 grid gap-8 grid-cols-2 lg:grid-cols-4">
            {/* <!-- TW Elements section --> */}
            <div className="col-span-2 sm:col-span-1">
              <img src={logo} alt="logo" width={112} />
            </div>
            {/* <!-- Products section --> */}
            <div>
              <ul className="flex flex-col justify-start">
                <li>
                  <p className="mb-4 text-xl font-bold">Company</p>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/about-us"}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/bloglist"}
                  >
                    Blogs
                  </NavLink>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/faqs"}
                  >
                    FAQs
                  </NavLink>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/contact-us"}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <!-- Useful links section --> */}
            <div className="">
              <ul className="flex flex-col justify-start">
                <li>
                  <p className="mb-4 text-xl font-bold">Support</p>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/service-agreement"}
                  >
                    Service Agreement
                  </NavLink>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/terms-and-conditions"}
                  >
                    Terms & Conditions
                  </NavLink>
                </li>
                <li className="mb-4">
                  <NavLink
                    className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
                    to={"/privacy-policy"}
                  >
                    Privacy
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <!-- Contact section --> */}
            <div className="lg:w-[400px] col-span-2 w-auto items-start">
              <p className="mb-4 text-xl font-bold">Newsletter</p>
              <p className="mb-4">
                <p className="text-neutral-600 dark:text-neutral-200">
                  Join and get important news regularly
                </p>
              </p>

              <form className="max-w-md mx-auto mt-8">
                {/* <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label> */}
                <div className="relative">
                  <input
                    type="email"
                    onChange={handleNewsletterInput}
                    value={newsletterInput}
                    id="newsletter-input"
                    className="block w-full p-4 pl-4 text-sm text-start text-gray-900 border bg-white rounded-full"
                    placeholder="Enter your e-mail..."
                  />
                  <button
                    type="button"
                    onClick={() =>
                      emailValid &&
                      newsletterInput !== "" &&
                      handleNewsletterSubmit()
                    }
                    className={`text-white absolute end-0.5 bottom-0.5 top-0.5 ${
                      emailValid || newsletterInput === ""
                        ? `bg-primary hover:bg-dark`
                        : `bg-gray-500`
                    }  transition ease-in-out duration-75 hover:cursor-pointer font-medium sm:text-sm sm:px-12 px-6 py-2 rounded-full text-xs`}
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr className="border-gray-200 sm:mx-auto dark:border-gray-700" />

        <div className="flex items-center justify-center p-6 lg:justify-between flex-col sm:flex-row gap-4 sm:gap-0">
          <div className="sm:mr-12 lg:block">
            <span>Copyright @2024 Dubai Job Finder Inc.</span>
          </div>

          {/* <div className="flex justify-center">
            <a className="mr-6 text-neutral-600 dark:text-neutral-200">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>
            <a className="mr-6 text-neutral-600 dark:text-neutral-200">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <a className="mr-6 text-neutral-600 dark:text-neutral-200">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a className="mr-6 text-neutral-600 dark:text-neutral-200">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
