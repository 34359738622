import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Countries from "../../utils/countryWithFlags.json";
import { Checkbox, DatePicker, Form, Input, Radio, Select, Upload, Row, Col, Button, Modal, message, Image } from "antd";
import { postJobApplication } from "../../helpers/UserApi";
import toast from "react-hot-toast";
import { getBase64 } from "../../utils/utils";
import { confirmModalRef } from "./ConfirmModal";
import { useNavigate } from "react-router-dom";

const getFlagUrlByCountry = (countryName) => {
  const country = Countries.find((c) => c.name === countryName);
  return country ? country.flag : null;
};

const ApplicationFormModal = ({ open, setOpen, jobId }) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const onFinish = async (values) => {
    let toastBox = toast;
    const dob = values.dob;
    const date = dob.format("YYYY-MM-DD");
    try {
      setSubmitLoading(true);
      const postJobApplicationResult = await postJobApplication({
        ...values,
        jobId: jobId,
        dob: date,
        // currentAddr: values.currentAddr || 'Not provided',
        // permaAddr: values.permaAddr || 'Not provided',
      });
      setOpen(!open);
      confirmModalRef.current.open("HR team will review CV and get back to you soon!", "Application submitted", async () => {
        navigate("/joblist");
        confirmModalRef.current.close();
      });
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const validateRequired = ({ getFieldValue }) => ({
    validator(_, value) {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
  });
  const validateCheckboxGroup = ({ getFieldValue }) => ({
    validator(_, value) {
      if (value && value.length > 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("At least one option must be selected!"));
    },
  });

  const beforeUpload = (file) => {
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e?.fileList?.find((element) => element.size / 1024 / 1024 < 5)) return [e?.fileList[0]];
    else return null;
  };

  const prefixSelector = (
    <Form.Item name="prefix" va noStyle>
      <Select className="w-fit">
        <Select.Option value="91">+91</Select.Option>
        <Select.Option value="94">+94</Select.Option>
        <Select.Option value="977">+977</Select.Option>
        <Select.Option value="971">+971</Select.Option>
      </Select>
    </Form.Item>
  );

  const handleFileRemove = async (file) => {
    setFile(null);
  };
  // const getFlagUrlByCountry = (countryName) => {
  //   const country = Countries.find(c => c.country === countryName);
  //   return country ? country.flag : null;
  // };
  const prioritizedCountries = ["India", "Nepal", "Sri Lanka"];
  const otherCountries = Countries.filter((c) => !prioritizedCountries.includes(c.name));

  const LanguageSkill = ({ title }) => {
    const prefix = title.substring(0, 3);

    return (
      <>
        <Col span={5}>
          <p className="font-bold">{title} : </p>
        </Col>
        <Col span={19}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "32px",
              alignItems: "center",
            }}
          >
            <Checkbox value={`${prefix}_speak`}>Speak</Checkbox>
            <Checkbox value={`${prefix}_read`}>Read</Checkbox>
            <Checkbox value={`${prefix}_write`}>Write</Checkbox>
          </div>
        </Col>
      </>
    );
  };

  return (
    <>
      {open && (
        <Modal
          open={open}
          centered
          onCancel={handleCancel}
          width={800}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" loading={submitLoading} type="primary" onClick={handleSubmit} className="bg-primary">
              Submit
            </Button>,
          ]}
        >
          <div className="overflow-y-scroll pb-4">
            <Form
              form={form}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 12 }}
              layout="horizontal"
              style={{ maxHeight: "70vh" }}
              className="sm:pr-0 pr-4"
              onFinish={onFinish}
              requiredMark={false}
              size="large"
              initialValues={{
                prefix: "91",
              }}
            >
              <div className="pb-2 pt-8">
                {/* EMAIL */}
                <Form.Item
                  label="E-Mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Input placeholder="E-Mail" />
                </Form.Item>
                {/* NAME */}
                <Form.Item label="Name" style={{ marginBottom: 0 }}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter First Name",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Last Name",
                      },
                    ]}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Form.Item>
                {/* CURRENT ADDRESS */}
                <Form.Item label="Current Address" name="currentAddr">
                  <Input placeholder="Current Address" />
                </Form.Item>
                {/* PERMANENT ADDRESS */}
                <Form.Item label="Permanent Address" name="permaAddr">
                  <Input placeholder="Permanent Address" />
                </Form.Item>
                {/* MARITAL STATUS */}
                <Form.Item
                  label="Marital Status"
                  name="maritalStatus"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Select placeholder="Select Marital Status">
                    <Select.Option value="married">Married</Select.Option>
                    <Select.Option value="unmarried">Unmarried</Select.Option>
                  </Select>
                </Form.Item>
                {/* GENDER */}
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Select placeholder="Select Gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                </Form.Item>
                {/* DATE OF BIRTH */}
                <Form.Item
                  label="Date of Birth"
                  name="dob"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <DatePicker format="DD-MM-YYYY" placeholder="DD-MM-YYYY" style={{ width: "100%" }} />
                </Form.Item>
                {/* VISA STATUS */}
                <Form.Item
                  label="Visa Status"
                  name="visaStatus"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Radio.Group>
                    <Radio value="visit"> Visit </Radio>
                    <Radio value="spouse"> Spouse </Radio>
                    <Radio value="residence"> Residence </Radio>
                    <Radio value="none"> None </Radio>
                  </Radio.Group>
                </Form.Item>
                {/* PASSPORT STATUS */}
                <Form.Item
                  label="Do you have Passport?"
                  name="passport"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Radio.Group>
                    <Radio value="true"> Yes </Radio>
                    <Radio value="false"> No </Radio>
                  </Radio.Group>
                </Form.Item>
                {/* LANGUAGE */}
                <Form.Item name="languages" label="Language" rules={[validateCheckboxGroup]}>
                  <Checkbox.Group style={{ lineHeight: "41px" }}>
                    <Row style={{ alignItems: "center" }}>
                      <LanguageSkill title={"English"} />
                      <LanguageSkill title={"Hindi"} />
                      <LanguageSkill title={"Kannada"} />
                      <LanguageSkill title={"Telugu"} />
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                {/* NATIONALITY */}
                <Form.Item
                  label="Nationality"
                  name="nationality"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Select placeholder="Select Nationality">
                    {/* Render prioritized countries first */}
                    {prioritizedCountries.map((country) => (
                      <Select.Option key={country} value={country}>
                        <div className="flex items-center">
                          <img src={getFlagUrlByCountry(country)} alt={`${country} flag`} className="w-6 h-6 mr-2" />
                          {country}
                        </div>
                      </Select.Option>
                    ))}

                    {/* Render the remaining countries */}
                    {/* {otherCountries.map((element) => (
                      <Select.Option
                        key={element.country}
                        value={element.country}
                      >
                        <div className="flex items-center">
                          <img
                            src={element.flag}
                            alt={`${element.country} flag`}
                            className="w-6 h-6 mr-2"
                          />
                          {element.country}
                        </div>
                      </Select.Option>
                    ))} */}
                  </Select>
                </Form.Item>
                {/* PHONE NUMBER */}
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    style={{
                      width: "100%",
                    }}
                    maxLength={10}
                    placeholder="0123456789"
                  />
                </Form.Item>
                {/* RESUME UPLOAD */}
                <Form.Item
                  label="Resume"
                  name="resume_upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Upload maxCount={1} beforeUpload={beforeUpload} onPreview={handlePreview} onRemove={handleFileRemove} accept=".doc,.pdf,.jpeg,.jpg,.png" listType="picture-card">
                    {file ? null : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ApplicationFormModal;
