import React, { useState } from "react";
import logo from "../../../data/logo/PNG/Dubai Job finder logo final file_Logo white.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CgMenuLeft, CgClose } from "react-icons/cg";
import { Drawer } from "antd";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg-primary h-24 flex items-center w-full mx-auto text-center justify-between 2xl:px-56 xl:px-36 lg:px-36 sm:px-16 px-8 shadow">
      <button
        className="md:hidden flex"
        onClick={showDrawer}
        aria-label="Open Menu Button"
      >
        <CgMenuLeft size={30} className="text-white" />
      </button>
      <img
        src={logo}
        alt="logo"
        width={112}
        onClick={() => navigate("/")}
        className="hover:cursor-pointer"
      />
      <nav className=" items-center gap-10 text-white md:flex hidden">
        {location.pathname === "/" ? (
          <>
            <ScrollLink
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
              href="#home"
            >
              Home
            </ScrollLink>

            <ScrollLink
              activeClass="active"
              to="how-it-works"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
              className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
              href="#how-it-works"
            >
              How it Works
            </ScrollLink>

            <ScrollLink
              activeClass="active"
              to="find-jobs"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
              className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
              href="#find-jobs"
            >
              Find Jobs
            </ScrollLink>

            <ScrollLink
              activeClass="active"
              to="job-guides"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
              className="hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
              href="#job-guides"
            >
              Job Guides
            </ScrollLink>
          </>
        ) : (
          <>
            <NavLink
              to={"/"}
              href="/"
              className={
                "hover:cursor-pointer transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300 hover:text-indigo-300"
              }
            >
              Home
            </NavLink>
          </>
        )}
      </nav>

      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        key="left"
        width={300}
      >
        <ul className="flex flex-col items-start 2xl:px-48 xl:px-32 lg:px-32 sm:px-12 px-6 text-lg font-normal">
          <CgClose size={40} className="p-2 mb-10" onClick={onClose} />
          {location.pathname === "/" ? (
            <>
              <ScrollLink
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className="hover:cursor-pointer p-2 font-manrope"
                onClick={() => onClose()}
                href="#home"
              >
                Home
              </ScrollLink>
              <ScrollLink
                activeClass="active"
                to="how-it-works"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                className="hover:cursor-pointer p-2 font-manrope"
                onClick={() => onClose()}
                href="#how-it-works"
              >
                How it Works
              </ScrollLink>

              <ScrollLink
                activeClass="active"
                to="find-jobs"
                spy={true}
                smooth={true}
                offset={10}
                duration={500}
                className="hover:cursor-pointer p-2 font-manrope"
                onClick={() => onClose()}
                href="#find-jobs"
              >
                Find Jobs
              </ScrollLink>

              <ScrollLink
                activeClass="active"
                to="job-guides"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                className="hover:cursor-pointer p-2 font-manrope"
                onClick={() => onClose()}
                href="#job-guides"
              >
                Job Guides
              </ScrollLink>
            </>
          ) : (
            <>
              <NavLink
                to={"/"}
                href="/"
                className="hover:cursor-pointer p-2 font-manrope"
              >
                Home
              </NavLink>
            </>
          )}
        </ul>
      </Drawer>
    </div>
  );
};

export default Navbar;
