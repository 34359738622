import React, { useEffect, useState } from "react";
import Navbar from "../homepage/components/Navbar";
import { Collapse, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import FaqListHero from "./components/FaqListHero";
import Footer from "../homepage/components/Footer";
import { IoIosArrowDown } from "react-icons/io";
import { getFaqList } from "../../helpers/FaqApi";
import { Helmet } from "react-helmet";

const FaqList = () => {
  const [faqDetails, setFaqDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFaqDetailsFunc = async () => {
    try {
      setLoading(true);
      const fetchFaqDetailsResult = await getFaqList();
      setFaqDetails(
        fetchFaqDetailsResult?.map((element, index) => ({
          key: index,
          label: (
            <span className="sm:text-2xl text-xl font-medium w-full text-start mr-auto flex items-center gap-3">
              {index + 1}. {element.title}
            </span>
          ),
          children: <div className="editor" dangerouslySetInnerHTML={{ __html: element.content || "" }} />,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqDetailsFunc();
  }, []);

  const items = [
    {
      key: "1",
      label: <span className="sm:text-2xl text-xl font-medium w-full text-start mr-auto flex items-center gap-3">Seamless searching</span>,
      children: <span className="sm:text-base text-sm">Experience seamless job searching with our intuitive list page, designed for easy navigation and quick access to the opportunities you're looking for.</span>,
    },
    {
      key: "2",
      label: <span className="sm:text-2xl text-xl font-medium w-full text-start mr-auto flex items-center gap-3">Easy to apply</span>,
      children: <span className="sm:text-base text-sm">Applying for jobs has never been easier! Fill out a simple form directly on our platform and submit your application to potential employers instantly.</span>,
    },
  ];
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ List - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/faqs" />
        <meta name="description" content="Find answers to frequently asked questions at Dubai Job Finder. Our FAQ section covers everything you need to know about job searching, hiring processes, and services to help streamline your job search in Dubai." />
        <meta name="keywords" content="Dubai Job Finder FAQ, frequently asked questions Dubai jobs, job search FAQs Dubai, hiring process questions Dubai, Dubai employment FAQs, job finder help, FAQ Dubai careers, Dubai job service questions" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="FAQ List" />
        <meta property="og:description" content="Find answers to frequently asked questions at Dubai Job Finder. Our FAQ section covers everything you need to know about job searching, hiring processes, and services to help streamline your job search in Dubai." />
        <meta property="og:url" content="https://dubaijobfinder.net/faqs" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>

      <Navbar />
      <Spin
        spinning={loading}
        fullscreen
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
      <FaqListHero />
      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-32 sm:px-10 px-6 container mx-auto h-screen pt-10">
        {/* <div className="bg-white w-full mx-auto text-center justify-center">
                    <div className="w-full flex items-center mx-auto justify-center mt-8 sm:mb-32 mb-10">
                        <div className="gap-6 grid sm:grid-cols-3 grid-cols-1 w-full">
                            {blogDetails
                                ?.map((element) => (
                                    <JobGuideCards imgSrc={`${API_URL}${APP_BLOG_IMAGE_URL}/${element?.blog_image_name}`} blogDate={element?.created_at} title={element?.title} blogId={element?.id} />
                                ))}
                        </div>
                    </div>
                </div> */}
        <Collapse items={faqDetails} bordered={false} expandIconPosition="end" defaultActiveKey={["1"]} extra={true} expandIcon={({ isActive }) => <IoIosArrowDown style={{ transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.2s ease" }} />} />
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};

export default FaqList;
