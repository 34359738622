import React, { useEffect, useState } from "react";
import Navbar from "../homepage/components/Navbar";
import { LoadingOutlined } from "@ant-design/icons";
import Footer from "../homepage/components/Footer";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import BlogContent from "./components/BlogContent";
import BlogHero from "./components/BlogHero";
import { getBlogList } from "../../helpers/BlogApi";
import { API_URL, APP_BLOG_IMAGE_URL } from "../../helpers/AppApiUrls";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const location = useLocation();
  const [blogId, setBlogId] = useState(null);
  const [blogDetails, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const blogIdRaw = location.pathname.split("/")[2];
    setBlogId(blogIdRaw);
  }, [location]);

  const fetchJobDetailsFunc = async () => {
    try {
      setLoading(true);
      const fetchJobDetailsResult = await getBlogList({ id: blogId });
      setBlogDetails(fetchJobDetailsResult[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (blogId) fetchJobDetailsFunc();
    // eslint-disable-next-line
  }, [blogId]);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.yourwebsite.com/blogs/${blogId}`,
    },
    headline: blogDetails?.title,
    description: blogDetails?.content?.replace(/<\/?[^>]+(>|$)/g, ""),
    image: `${API_URL}${APP_BLOG_IMAGE_URL}/${blogDetails?.blog_image_name}`,
    publisher: {
      "@type": "Organization",
      name: "Dubai Job Finder",
      logo: {
        "@type": "ImageObject",
        url: "https://dubaijobfinder.net/ogImage.png",
      },
    },
    datePublished: blogDetails?.created_at,
    dateModified: blogDetails?.created_at,
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Dubai Job Finder</title>
        <link rel="canonical" href={`https://dubaijobfinder.net/blogs/${blogId}`} />
        <meta name="description" content="Read the latest articles, insights, and tips on Dubai's job market on Dubai Job Finder Blogs. Our blog provides valuable information to help job seekers succeed in finding their ideal job in Dubai's competitive market." />
        <meta name="keywords" content="Dubai job market, career advice in Dubai, job seeking tips Dubai, Dubai employment trends, Dubai job search strategies, professional growth in Dubai, finding jobs in Dubai, Dubai career opportunities, job seeker guide Dubai" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Blogs - Dubai Job Finder" />
        <meta property="og:description" content="Read the latest articles, insights, and tips on Dubai's job market on Dubai Job Finder Blogs. Our blog provides valuable information to help job seekers succeed in finding their ideal job in Dubai's competitive market." />
        <meta property="og:url" content={`https://dubaijobfinder.net/blogs/${blogId}`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${API_URL}${APP_BLOG_IMAGE_URL}/${blogDetails?.blog_image_name}`} />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>

      <Navbar />
      <Spin
        spinning={loading}
        fullscreen
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
      <div id="hero">
        <BlogHero blogTitle={blogDetails?.title} blogDate={blogDetails?.created_at} />
      </div>
      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-32 sm:px-10 px-6 container mx-auto mt-14 mb-36">
        <img src={`${API_URL}${APP_BLOG_IMAGE_URL}/${blogDetails?.blog_image_name}`} alt="blog-image" className="max-h-[400px] mx-auto mb-10" />

        <BlogContent content={blogDetails?.content} />
      </div>

      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default Blogs;
