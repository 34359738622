import React from "react";

import { NavLink } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { convertUTCtoIST } from "../../utils/utils";

const JobGuideCards = ({ imgSrc, blogDate, title, blogId }) => {
  return (
    <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04) pt-4">
      <a href={`/blogs/${blogId}`}>
        <img className="rounded-2xl h-[280px] object-cover" src={imgSrc} alt="blog-image" />
      </a>
      <div className="pt-4 text-start">
        <p className="mt-4 mb-4 text-md leading-tight font-light text-gray-400">Latest blog, {convertUTCtoIST(blogDate)}</p>
        <p className="mb-8 font-extrabold text-black text-xl">{title}</p>
        <NavLink className="font-bold flex items-center gap-2" to={`/blogs/${blogId}`}>
          Read More <span className="sr-only">about {title}</span> <BsArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default JobGuideCards;
