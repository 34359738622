import axios from "axios";
import { API_URL, APP_FAQ_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_FAQ_BASE_URL}`;

export async function getFaqList(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/`, {
      params: query,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    if (err?.response?.data) return Promise.reject(err?.response?.data?.error);
    return Promise.reject("Failed to retrieve FAQs");
  }
}
