import { Space } from "antd";
import React from "react";

const UserFlowCard = ({ flowNumber, title, description }) => {
  return (
    <div className="border rounded-3xl px-10 py-10 mx-auto justify-center sm:max-w-[23rem] h-full">
      <Space direction="vertical">
        <div className="rounded-full bg-primary px-4 py-2 text-white max-w-fit text-center mx-auto">{flowNumber}</div>
        <div className="text-xl font-bold">{title}</div>
        <div className="text-sm">{description}</div>
      </Space>
    </div>
  );
};

export default UserFlowCard;
