import React from "react";
import smilingPerson2Img from "../../../data/assets/similing_person2.png";
import { Collapse } from "antd";
import { IoIosArrowDown } from "react-icons/io";
import searchIcon from "../../../data/assets/searchIcon.png";
import multiUserIcon from "../../../data/assets/multiUserIcon.png";
import { useNavigate } from "react-router-dom";

const SubHero = () => {
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <span className="sm:text-2xl text-xl font-medium w-full text-start mr-auto flex items-center gap-3">
          <img src={searchIcon} alt="searchIcon" width={40} className="" />
          Seamless searching
        </span>
      ),
      children: <span className="sm:text-base text-sm">Experience seamless job searching with our intuitive list page, designed for easy navigation and quick access to the opportunities you're looking for.</span>,
    },
    {
      key: "2",
      label: (
        <span className="sm:text-2xl text-xl font-medium w-full text-start mr-auto flex items-center gap-3">
          <img src={multiUserIcon} alt="userIcon" width={40} className="" />
          Easy to apply
        </span>
      ),
      children: <span className="sm:text-base text-sm">Applying for jobs has never been easier! Fill out a simple form directly on our platform and submit your application to potential employers instantly.</span>,
    },
  ];

  return (
    <div className="bg-white w-full grid lg:grid-cols-3 grid-cols-1 mx-auto text-center justify-center mb-10">
      <div className="text-4xl font-semibold mx-auto items-center justify-center text-center col-span-1">
        <img src={smilingPerson2Img} alt="personImage" className="mx-auto items-center justify-center text-center sm:w-[22rem] w-full" />
      </div>
      <div className="mx-auto justify-start lg:pr-7 lg:ml-7 lg:col-span-2 flex-col flex lg:mt-0 mt-4">
        <div className="sm:text-5xl text-3xl font-bold text-start">Get the job of your dream quickly.</div>
        <div className="sm:text-base text-sm mt-4 text-start">Discover and apply to your dream job effortlessly. Our exclusive portal connects you directly with top employers, fast-tracking your career ambitions.</div>
        <div className="text-start mt-4">
          <Collapse items={items} bordered={false} expandIconPosition="end" defaultActiveKey={["1", "2"]} extra={true} expandIcon={({ isActive }) => <IoIosArrowDown style={{ transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.2s ease" }} />} />
        </div>
        <div className="text-start mt-auto">
          <button onClick={() => navigate("/joblist")} type="primary" shape="round" size="large" className="md:py-4 py-3 sm:w-auto w-full text-center md:mt-0 mt-4 md:px-10 px-8 text-lg bg-primary text-white rounded-full sm:text-start mr-auto">
            Find Jobs
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubHero;
