import axios from "axios";
import { API_URL, APP_JOB_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_JOB_BASE_URL}`;
//const BASE_URL = "http://localhost:8000/app/api/user";
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

// export const axiosPrivate = axios.create({
//   baseURL: `${BASE_URL}/refresh`,
//   headers: { "Content-Type": "application/json" },
//   withCredentials: true,
// });

export async function getJobList(query) {
  try {
    const { data } = await axios.get(`${BASE_URL}/`, {
      params: query,
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject("NO_USER_LOGIN");
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function getJobCategories() {
  try {
    const { data } = await axios.get(`${BASE_URL}/job-category`, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function getJobTypes() {
  try {
    const { data } = await axios.get(`${BASE_URL}/job-type`, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function getJobLocations() {
  try {
    const { data } = await axios.get(`${BASE_URL}/job-location`, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function updateJobViews(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/job-views`, details, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}
