import axios from "axios";
import { API_URL, APP_USER_BASE_URL } from "./AppApiUrls";

const BASE_URL = `${API_URL}${APP_USER_BASE_URL}`;
//const BASE_URL = "http://localhost:8000/app/api/user";
//const BASE_URL = "http://13.232.11.86:8000/app/api/user";
//const BASE_URL = "http://192.168.0.109:8000/app/api/user";

// export const axiosPrivate = axios.create({
//   baseURL: `${BASE_URL}/refresh`,
//   headers: { "Content-Type": "application/json" },
//   withCredentials: true,
// });

export async function postJobApplication(details) {
  try {
    const formData = new FormData();
    formData.append("file", details.resume_upload[0]?.originFileObj);
    formData.append("jobId", details.jobId);
    formData.append("firstName", details.firstName);
    formData.append("lastName", details.lastName);
    formData.append("email", details.email);
    formData.append("phoneNumber", details.phoneNumber);
    formData.append("currentAddr", details.currentAddr);
    formData.append("permaAddr", details.permaAddr);
    formData.append("dob", JSON.parse(JSON.stringify(details.dob)));
    formData.append("gender", details.gender);
    formData.append("maritalStatus", details.maritalStatus);
    formData.append("nationality", details.nationality);
    formData.append("visaStatus", details.visaStatus);
    formData.append("passport", details.passport === "true" ? 1 : 0);
    formData.append("languages", details.languages);

    const { data } = await axios.post(`${BASE_URL}/application`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // jobId: details?.jobId,
      },
      withCredentials: true, // Ensure cookies are sent if required
    });

    if (data?.success) {
      return Promise.resolve();
    } else {
      return Promise.reject(data?.error);
    }
  } catch (err) {
    return Promise.reject(err?.message);
  }
}

export async function deleteOnRemoveFile(fileName) {
  try {
    const { data } = await axios.delete(`${BASE_URL}/upload`, {
      data: { filename: fileName },
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data?.data);
    else return Promise.reject("NO_USER_LOGIN");
  } catch (err) {
    return Promise.reject("Auth Failed");
  }
}

export async function subscribeToNewsletter(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/newsletter`, details, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err?.message);
  }
}

export async function postInquiry(details) {
  try {
    const { data } = await axios.post(`${BASE_URL}/inquiry`, details, {
      headers: { "Content-Type": "application/json" },
    });
    if (data?.success) return Promise.resolve(data);
    else return Promise.reject(data?.error);
  } catch (err) {
    return Promise.reject(err?.message);
  }
}
