import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCmsContent } from "../../helpers/CmsApi";
import { Spin } from "antd";
import Navbar from "../homepage/components/Navbar";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const [cmsContent, setCmsContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const cmsContentFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const getCmsContentResult = await getCmsContent("about-us");
      setCmsContent(getCmsContentResult?.content);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    cmsContentFunc();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/about-us" />
        <meta name="description" content="Learn more about Dubai Job Finder, your trusted partner in navigating Dubai's dynamic job market. Discover our mission, values, and how we help connect job seekers with their ideal employers." />
        <meta name="keywords" content="About Dubai Job Finder, Dubai Job Finder mission, Dubai job market, career opportunities in Dubai, job search help Dubai, employment services Dubai, finding jobs in Dubai, Dubai Job Finder company, Dubai Job Finder background" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="About Us" />
        <meta property="og:description" content="Learn more about Dubai Job Finder, your trusted partner in navigating Dubai's dynamic job market. Discover our mission, values, and how we help connect job seekers with their ideal employers." />
        <meta property="og:url" content="https://dubaijobfinder.net/about-us" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>
      <Navbar />
      <Spin spinning={loading} fullscreen />
      <div dangerouslySetInnerHTML={{ __html: cmsContent || "" }} className="editor"></div>;
    </div>
  );
};

export default AboutUs;
