import React from "react";
import Navbar from "../homepage/components/Navbar";
import { Button, Form, Input, Select } from "antd";
import { postInquiry } from "../../helpers/UserApi";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    let toastBox = toast;
    try {
      await postInquiry(values);
      toast.success("Submitted!", {
        id: toastBox,
      });
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
    console.log(values);
  };
  const validateRequired = ({ getFieldValue }) => ({
    validator(_, value) {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
  });

  const prefixSelector = (
    <Form.Item name="prefix" va noStyle>
      <Select
        style={{
          width: 75,
        }}
      >
        <Select.Option value="91">+91</Select.Option>
        <Select.Option value="94">+94</Select.Option>
        <Select.Option value="977">+977</Select.Option>
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/contact-us" />
        <meta name="description" content="Get in touch with Dubai Job Finder. Find all the contact information you need including our phone number, email, and office location to reach out with any inquiries or support needs." />
        <meta name="keywords" content="Contact Dubai Job Finder, Dubai Job Finder contact information, reach Dubai Job Finder, Dubai Job Finder support, Dubai Job Finder office, Dubai Job Finder email, Dubai Job Finder phone number, talk to Dubai Job Finder" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content="Get in touch with Dubai Job Finder. Find all the contact information you need including our phone number, email, and office location to reach out with any inquiries or support needs." />
        <meta property="og:url" content="https://dubaijobfinder.net/contact-us" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>

      <Navbar />
      <div className="px-6 mx-auto container justify-center text-center">
        <div className="border container justify-center mx-auto mt-10 py-10 rounded-lg sm:px-10 px-6">
          <div className="text-center ">
            <span className="text-4xl font-semibold">Contact Us</span>
          </div>
          <div className="container mx-auto justify-center text-center">
            <Form
              form={form}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 12 }}
              layout="horizontal"
              style={{ maxHeight: "70vh" }}
              className="sm:pr-0 pr-4"
              onFinish={onFinish}
              requiredMark={false}
              size="large"
              initialValues={{
                prefix: "91",
              }}
            >
              <div className="pb-2 pt-8">
                {/* EMAIL */}
                <Form.Item
                  label="E-Mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Input placeholder="E-Mail" />
                </Form.Item>
                {/* NAME */}
                <Form.Item
                  label="Full Name"
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Full Name",
                    },
                  ]}
                  //   style={{
                  //     display: "inline-block",
                  //     width: "calc(50% - 8px)",
                  //   }}
                >
                  <Input placeholder="Full name" />
                </Form.Item>
                {/* PHONE NUMBER */}
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                    },
                    validateRequired,
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    style={{
                      width: "100%",
                    }}
                    maxLength={10}
                    placeholder="0123456789"
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  label="Message"
                  rules={[
                    {
                      required: true,
                      message: "Please write a message",
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="Please write your message" />
                </Form.Item>
              </div>
            </Form>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                form.submit();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
