import React, { useEffect, useState } from "react";
import { Collapse, Select, Space, Slider, Input, ConfigProvider, Drawer } from "antd";
import { GiCheckMark } from "react-icons/gi";
import { IoIosArrowDown } from "react-icons/io";
import { CloseOutlined } from "@ant-design/icons";
import { getJobCategories, getJobList, getJobLocations, getJobTypes } from "../../helpers/JobApi";
import toast from "react-hot-toast";
// import { createStyles, useTheme } from "antd-style";

const { Option } = Select;

const FilterComponent = ({ setJobList, setLoading }) => {
  const [salaryRange, setSalaryRange] = useState([100, 50000]);
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const [experience, setExperience] = useState({
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [jobCategories, setJobCategories] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobLocations, setJobLocations] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleJobTypeChange = (id) => {
    setJobTypes((prev) => prev.map((jobType) => (jobType.id === id ? { ...jobType, checked: !jobType.checked } : jobType)));
    // } ({ ...prev, [type]: !prev[type] }));
  };

  const handleExperienceChange = (years) => {
    setExperience((prev) => ({ ...prev, [years]: !prev[years] }));
  };

  const getRangeOfExperience = () => {
    const trueKeys = Object.keys(experience).filter((key) => experience[key]);
    if (trueKeys.length === 0) {
      return ""; // Return an empty string if no keys are true
    }
    const smallest = Math.min(...trueKeys) || 0;
    const largest = Math.max(...trueKeys);
    return `${smallest}-${largest}`;
  };

  const applyFilters = async () => {
    let values = {};
    values["location"] = location;
    values["category"] = category;
    values["types"] = jobTypes.filter((element) => element.checked);
    values["salaryRange"] = salaryRange.join("-");
    values["expRange"] = getRangeOfExperience();
    try {
      // let params = {};
      // const searchText = filter?.search;
      // if (searchText) params["search"] = searchText;
      setLoading(true);
      setDrawerOpen(false);
      const jobListResult = await getJobList(values);
      setJobList(jobListResult);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filterOption = (input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeSlider = (value) => {
    setSalaryRange(value);
  };

  //APIs

  const fetchJobCategoriesFunc = async () => {
    let toastBox = toast;
    try {
      const getJobCategoriesResult = await getJobCategories();
      setJobCategories(getJobCategoriesResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  const fetchJobTypesFunc = async () => {
    let toastBox = toast;
    try {
      const getJobTypesResult = await getJobTypes();
      setJobTypes(getJobTypesResult?.map((element) => ({ ...element, checked: false })));
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  const fetchJobLocationsFunc = async () => {
    let toastBox = toast;
    try {
      const getJobLocationsResult = await getJobLocations();
      setJobLocations(getJobLocationsResult);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    }
  };

  ////

  useEffect(() => {
    fetchJobTypesFunc();
    fetchJobCategoriesFunc();
    fetchJobLocationsFunc();
  }, []);

  const items = [
    {
      key: "1",
      label: <span className="font-extrabold text-lg font-manrope">Filter By</span>,
      children: (
        <div>
          <div className="2xl:flex grid sm:grid-cols-2 grid-cols-1 w-full gap-12 sm:text-start text-center sm:justify-between justify-center">
            <div className="flex flex-col xl:space-y-8">
              <div className="flex flex-col space-y-2 mb-4">
                <span className="font-extrabold text-base font-manrope">Location</span>
                <Space wrap className="sm:justify-normal justify-center">
                  <Select placeholder="Choose job location" showSearch optionFilterProp="children" onChange={setLocation} filterOption={filterOption} size="large" className="sm:w-[280px] w-[87vw]">
                    <Option value="">All</Option>
                    {jobLocations?.map((element, index) => (
                      <Option key={index} value={element.id}>
                        {element.name}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </div>
              <div className="flex flex-col space-y-2 justify-center">
                <span className="font-extrabold text-base font-manrope">Category</span>
                <Space wrap className="sm:justify-normal justify-center">
                  <Select placeholder="Choose job category" showSearch optionFilterProp="children" onChange={setCategory} filterOption={filterOption} size="large" className="sm:w-[280px] w-[87vw]">
                    <Option value="">All</Option>
                    {jobCategories?.map((element, index) => (
                      <Option key={index} value={element?.id}>
                        {element?.name}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </div>
            </div>
            <div className="space-y-4">
              <span className="font-extrabold text-base font-manrope">Job Type</span>
              <ul className="space-y-2">
                {jobTypes?.map((element) => (
                  <li key={element?.id} className="flex space-x-2 text-base text-gray-500 font-manrope">
                    <div className="flex flex-col justify-center items-center">
                      <label className="cursor-pointer relative">
                        <input type="checkbox" checked={element?.checked} onChange={() => handleJobTypeChange(element?.id)} className="appearance-none w-5 h-5 rounded-md border-2 border-gray-500" />
                        <GiCheckMark className={`w-3 h-3 text-lg text-gray-500 absolute top-1 left-1 ${element?.checked ? "text-opacity-100" : "text-opacity-0"}`} />
                      </label>
                    </div>
                    <span onClick={() => handleJobTypeChange(element?.id)} className="hover:cursor-pointer">
                      {element?.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="space-y-4">
              <span className="font-extrabold text-base font-manrope">Experience</span>
              <ul className="space-y-2">
                {[2, 3, 4, 5].map((years) => (
                  <li key={years} className="flex space-x-2 text-base text-gray-500 font-manrope">
                    <div className="flex flex-col justify-center items-center">
                      <label className="cursor-pointer relative">
                        <input type="checkbox" checked={experience[years]} onChange={() => handleExperienceChange(years)} className="appearance-none w-5 h-5 rounded-md border-2 border-gray-500" />
                        <GiCheckMark className={`w-3 h-3 text-lg text-gray-500 absolute top-1 left-1 ${experience[years] ? "text-opacity-100" : "text-opacity-0"}`} />
                      </label>
                    </div>
                    <span onClick={() => handleExperienceChange(years)} className="hover:cursor-pointer">
                      {years} Years
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full space-y-4 xl:w-3/4 2xl:w-1/4">
              <span className="font-extrabold text-base font-manrope">Salary</span>
              <div className="flex space-x-2 text-sm justify-between mx-auto items-center w-full">
                <div className="flex sm:space-x-6 justify-between w-full gap-4">
                  <Input
                    min={100}
                    max={50000}
                    type="number"
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      if (newValue <= 50000 && newValue < salaryRange[1]) {
                        setSalaryRange([newValue, salaryRange[1]]);
                      }
                      // else if (newValue <= 0) {
                      //   setSalaryRange([0, salaryRange[1]]);
                      // }
                    }}
                    value={salaryRange[0]}
                  />
                  <Input
                    min={100}
                    max={50000}
                    type="number"
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      if (newValue <= 50000 && newValue > salaryRange[0]) {
                        setSalaryRange([salaryRange[0], newValue]);
                      } else if (newValue >= 50000) {
                        setSalaryRange([salaryRange[0], 50000]);
                      }
                    }}
                    value={salaryRange[1]}
                  />
                </div>
                <span>AED</span>
              </div>
              <Slider min={100} max={50000} value={salaryRange} range step={1000} onChange={onChangeSlider} className="w-full" />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button onClick={applyFilters} className="mt-4 py-3 lg:w-40 w-full bg-primary text-white rounded-full shadow-sm hover:bg-primary text-base font-manrope">
              Apply Filter
            </button>
          </div>
        </div>
      ),
    },
  ];

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: "#f9f9fb",
            headerBg: "rgba(236,236,244)",
            colorBorder: "#FFFFFF",
            borderRadiusLG: 16,
          },
          Slider: {
            handleActiveColor: "#444690",
            handleColor: "#444690",
            dotActiveBorderColor: "#444690",
            dotBorderColor: "#444690",
            trackBg: "#444690",
            trackHoverBg: "#444690",
            colorPrimaryBorderHover: "#444690",
          },
        },
      }}
    >
      <div className="hidden sm:block">
        <Collapse accordion c items={items} size="large" expandIconPosition="end" expandIcon={({ isActive }) => <IoIosArrowDown size={25} style={{ transform: isActive ? "rotate(180deg)" : "rotate(0deg)", transition: "transform 0.2s ease" }} />} />
      </div>
      <div className="bg-light rounded-2xl py-4 px-4 flex items-center justify-between sm:hidden" onClick={() => setDrawerOpen(!drawerOpen)}>
        <span className="font-extrabold text-lg font-manrope">Filter By</span>
        <IoIosArrowDown size={25} />
      </div>
      <Drawer
        title={
          <div className="flex items-center justify-between">
            <span className="font-extrabold text-lg font-manrope">Filter By</span>
            <CloseOutlined size={25} className="hover:cursor-pointer" onClick={() => setDrawerOpen(false)} />
          </div>
        }
        onClose={onDrawerClose}
        open={drawerOpen}
        styles={{
          body: {
            paddingBottom: 80,
            overflowX: "visible",
          },
        }}
        height={600}
        placement="bottom"
        closeIcon={false}
      >
        {items[0].children}
      </Drawer>
    </ConfigProvider>
  );
};

export default FilterComponent;
