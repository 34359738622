import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCmsContent } from "../../helpers/CmsApi";
import { Spin } from "antd";
import Navbar from "../homepage/components/Navbar";
import { Helmet } from "react-helmet";

const ServiceAgreement = () => {
  const [cmsContent, setCmsContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const cmsContentFunc = async () => {
    let toastBox = toast;
    try {
      setLoading(true);
      const getCmsContentResult = await getCmsContent("service-agreement");
      setCmsContent(getCmsContentResult?.content);
    } catch (error) {
      toast.error(`${error}`, {
        id: toastBox,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    cmsContentFunc();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Service Agreement - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/service-agreement" />
        <meta name="description" content="Review our Service Agreement to understand the terms and conditions under which Dubai Job Finder offers its services. We ensure transparency and fairness in all our user engagements." />
        <meta name="keywords" content="Dubai Job Finder service terms, service agreement, terms of service Dubai, user agreement Dubai, service conditions Dubai, job service terms, agreement details Dubai, service policy Dubai, employment service agreement Dubai" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Service Agreement" />
        <meta property="og:description" content="Review our Service Agreement to understand the terms and conditions under which Dubai Job Finder offers its services. We ensure transparency and fairness in all our user engagements." />
        <meta property="og:url" content="https://dubaijobfinder.net/service-agreement" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>
      <Navbar />
      <Spin spinning={loading} fullscreen />
      <div dangerouslySetInnerHTML={{ __html: cmsContent || "" }} className="editor"></div>;
    </div>
  );
};

export default ServiceAgreement;
