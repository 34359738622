import React, { useEffect, useState } from "react";
import UserFlowCard from "./subComponents/UserFlowCard";
import JobGuideCards from "../../globalComponents/JobGuideCards";
import jobGuide1 from "../../../data/assets/jobGuides1.png";
import jobGuide2 from "../../../data/assets/jobGuides2.png";
import jobGuide3 from "../../../data/assets/jobGuides3.png";
import { getBlogList } from "../../../helpers/BlogApi";
import { API_URL, APP_BLOG_IMAGE_URL } from "../../../helpers/AppApiUrls";

const JobGuides = () => {
  const [blogDetails, setBlogDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchJobDetailsFunc = async () => {
    try {
      setLoading(true);
      const fetchJobDetailsResult = await getBlogList();
      setBlogDetails(fetchJobDetailsResult);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobDetailsFunc();
  }, []);

  return (
    <div className="bg-white w-full mx-auto text-center justify-center">
      <div className="text-6xl mt-14 font-extrabold">Job Guides</div>
      <div className="w-full flex items-center mx-auto justify-center mt-8 sm:mb-32 mb-10">
        <div className="gap-6 grid sm:grid-cols-3 grid-cols-1 w-full">
          {/* <JobGuideCards imgSrc={jobGuide1} />
          <JobGuideCards imgSrc={jobGuide2} />
          <JobGuideCards imgSrc={jobGuide3} /> */}
          {blogDetails
            ?.filter((element) => element?.highlighting === "true")
            ?.filter((element, index) => index < 3)
            .map((element) => (
              <JobGuideCards imgSrc={`${API_URL}${APP_BLOG_IMAGE_URL}/${element?.blog_image_name}`} blogDate={element?.created_at} title={element?.title} blogId={element?.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default JobGuides;
