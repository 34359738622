import React from "react";
import img1 from "../../../data/assets/jobList1.png";
import img2 from "../../../data/assets/jobList2.png";
import { NavLink } from "react-router-dom";

const FaqListHero = () => {
  return (
    <div className="relative overflow-hidden w-full flex flex-row mx-auto justify-between items-center bg-opacity-10 bg-primary py-2 sm:py-16 pb-10">
      {/* Left Image */}
      <div className="absolute top-1/2 transform -translate-y-1/2 left-0 z-20 sm:static sm:transform-none sm:translate-y-0 pl-4 2xl:pl-80">
        <img src={img1} className="sm:w-[180px] sm:h-[160px] w-[160px] h-[113px] object-contain" alt="head-prop" />
      </div>

      {/* Center Content */}
      <div className="flex flex-col justify-center items-center mx-auto px-2 sm:px-10 z-10 py-2 mt-8">
        <span className="xl:text-5xl font-bold text-4xl text-center">FAQs</span>
        <span className="xl:text-lg font-medium text-base pt-4 sm:pt-8 text-center">
          <NavLink to="/" className="hover:text-primary transition duration-100 ease-in-out">
            Home
          </NavLink>
          {" > FAQs"}
        </span>
      </div>

      {/* Right Image */}
      <div className="absolute top-1/2 transform -translate-y-1/2 right-0 z-20 sm:static sm:transform-none sm:translate-y-0 pr-4 2xl:pr-80">
        <img src={img2} className="sm:w-[180px] sm:h-[160px] w-[160px] h-[113px] object-contain" alt="head-prop" />
      </div>
    </div>
  );
};

export default FaqListHero;
