import React, { useState } from "react";
import computerPersonImg from "../../../data/assets/smiling_person.webp";
import person1Img from "../../../data/assets/person1.png";
import person2Img from "../../../data/assets/person2.png";
import { Space } from "antd";
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const [searchValue, setSearchValue] = useState(null);
  const navigate = useNavigate();

  const handleSearchInput = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const handleSearchButton = () => {
    if (!searchValue) return;
    navigate(`/joblist?search=${searchValue}`);
  };
  return (
    <div className="relative  overflow-hidden w-full sm:flex sm:flex-row mx-auto justify-between bg-opacity-10 bg-primary sm:max-h-[70vh] sm:min-h-[80vh] 2xl:min-h-[90vh] h-[86vh] 2xl:px-64 xl:px-40 lg:px-40 md:px-32 sm:px-10 px-8">
      <div className="sm:grid sm:grid-cols-2 items-center w-full mx-auto z-10 sm:pt-0 pt-10">
        <div className="flex items-center w-full mx-auto z-10">
          <Space direction="vertical" className="gap-8">
            <div className="min-w-fit">
              <span className="2xl:text-6xl xl:text-5xl lg:text-5xl md:text-5xl text-4xl capitalize font-bold">
                Find & Hire
                <br />
                Experts
                <br />
                here.
              </span>
            </div>
            <div>
              <span className="2xl:text-2xl xl:text-xl lg:text-xl md:text-xl sm:text-xl text-lg">With the largest professional creative community online, simply search through from our website.</span>
            </div>
            <div className="mt-4 md:flex gap-4">
              <div>
                <div>
                  <input className="rounded-full text-start md:py-4 py-3 text-lg md:pr-20 pr-16 pl-6 w-full sm:w-auto" placeholder="Search job, title, etc..." value={searchValue} onChange={handleSearchInput} onKeyDown={(event) => event.key === "Enter" && handleSearchButton()} />
                  <div className="mt-3 w-full mx-auto text-center">
                    <span className="text-base font-medium text-primary">Popular: </span>
                    <span className="text-base">Design, Developer, Javascript</span>
                  </div>
                </div>
              </div>
              <div className="w-full mr-auto ">
                <button className="md:py-4 py-3 md:mt-0 mt-4 md:px-10 px-8 text-lg bg-primary text-white rounded-full text-center mx-auto" onClick={handleSearchButton} type="submit">
                  Search
                </button>
              </div>
            </div>
          </Space>
        </div>
      </div>
      <div className="sm:absolute right-36 sm:blur lg:blur-none">
        <img
          src={computerPersonImg}
          alt="Prop"
          className="transform scale-x-[-1] max-[360px]:w-[160px] xs:w-[270px] min-[392px]:w-[290px] sm:w-[400px] xl:w-[500px] 2xl:w-[620px] max-[360px]:translate-y-[10px] translate-y-[-4px] min-[410px]:translate-y-[50px] min-[468px]:translate-y-[80px]  sm:translate-y-[380px] md:translate-y-[340px] xl:translate-y-[240px] 2xl:translate-y-[200px]  translate-x-[20px] min-[609px]:translate-x-[140px]  sm:translate-x-[90px] 2xl:translate-x-0 "
        />
      </div>

      <div className="flex items-center sm:absolute sm:right-40 sm:top-36 sm:bottom-auto bottom-36 right-9 sm:blur lg:blur-none">
        <div className="bg-white rounded-full py-0.5 pl-2 pr-6 shadow-2xl">
          <div className="flex items-center">
            <div className="absolute sm:left-[2.5rem] left-[2rem]">
              <img src={person2Img} alt="user" className="rounded-full z-10 border-2 border-white sm:w-10 w-8" />
            </div>
            <div className="flex items-center">
              <img src={person1Img} alt="user" className="rounded-full border-2 border-white sm:w-10 w-8" />
              <div className="sm:ml-10 ml-7">
                <span className="font-bold text-sm">18k+</span>
                <br />
                <span className="text-xs">Individual Freelancer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
