import React, { useState } from "react";
import { Space, Tag } from "antd";
import { FaEye } from "react-icons/fa";
import ApplicationFormModal from "./ApplicationFormModal";
import { useNavigate } from "react-router-dom";
import { API_URL, APP_JOB_LOGO_URL } from "../../helpers/AppApiUrls";

const JobCard = ({ title, amount, views, jobType, expRange, job_id, logo_name, highlighting }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleCardSelect = (jobId) => {
    navigate(`/job-desc/${jobId}`);
  };

  return highlighting === "true" ? (
    <div
      className="group relative mx-auto xs:w-[92%] w-full overflow-hidden rounded-[16px] p-[4px] transition-all duration-300 ease-in-out bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:cursor-pointer hover:shadow-2xl hover:shadow-primary"
      onClick={() => handleCardSelect(job_id)}
    >
      <div className="animate-spin-slow absolute -top-40 -bottom-40 left-10 right-10 bg-gradient-to-r from-transparent via-white/90 to-transparent visible"></div>
      <div className="relative rounded-[15px] bg-white px-6 pb-6 pt-2">
        <ApplicationFormModal open={open} setOpen={(val) => setOpen(val)} jobId={job_id} />
        <Space direction="vertical" className="w-full">
          <p className="text-sm md:text-lg font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient text-start">
            Trending Job!
          </p>
          <div className="flex justify-between items-center">
            <img src={`${API_URL}${APP_JOB_LOGO_URL}/${logo_name}`} className="h-[3rem] w-[auto]" alt="logo-image" />
            <div className="text-start text-sm flex items-center gap-1">
              <span className="font-medium text-gray-400">
                <FaEye />{" "}
              </span>
              <span className="font-bold text-gray-400">{views}</span>
            </div>
          </div>
          <div className="w-full h-[120px]">
            <div className="text-start font-bold xs:text-xl text-base">
              <span>{title}</span>
            </div>
            <div className="text-start mt-4 text-base sm:text-base">
              <span className="font-semibold">{amount} </span>
              <span className="font-semibold">AED </span>
              <span className="font-light text-gray-400">/ Monthly</span>
            </div>
            <div className="flex mt-1">
              <Tag bordered={false} className=" text-primary font-medium">
                {jobType}
              </Tag>
              <Tag bordered={false} className=" text-primary font-medium">
                {expRange} Years
              </Tag>
            </div>
          </div>
          <div className="text-start">
            <button className="md:py-2 py-2 md:mt-0 mt-2 md:px-8 px-6 text-sm bg-primary text-white rounded-full text-center mx-auto w-full" onClick={showModal}>
              Apply
            </button>
          </div>
        </Space>
      </div>
    </div>
  ) : (
    <div className={`border-2 rounded-3xl sm:px-10 px-4 py-4 mx-auto justify-start xs:w-[92%] w-full h-full transition duration-180 ease-in-out hover:cursor-pointer hover:border-primary hover:border-2 hover:shadow-2xl hover:shadow-primary overflow-hidden`} onClick={() => handleCardSelect(job_id)}>
      <ApplicationFormModal open={open} setOpen={(val) => setOpen(val)} jobId={job_id} />
      <Space direction="vertical" className="w-full">
        <div className="flex justify-between items-center mt-4">
          <img src={`${API_URL}${APP_JOB_LOGO_URL}/${logo_name}`} className="h-[3rem] w-[auto]" alt="logo-image" />
          <div className="text-start text-sm flex items-center gap-1">
            <span className="font-medium text-gray-400">
              <FaEye />{" "}
            </span>
            <span className="font-bold text-gray-400">{views}</span>
          </div>
        </div>
        <div className="w-full h-[120px] mt-3">
          <div className="text-start font-bold xs:text-xl text-base">
            <span>{title}</span>
          </div>
          <div className="text-start mt-4 text-base sm:text-base">
            <span className="font-semibold">{amount} </span>
            <span className="font-semibold">AED </span>
            <span className="font-light text-gray-400">/ Monthly</span>
          </div>
          <div className="flex mt-1">
            <Tag bordered={false} className=" text-primary font-medium">
              {jobType}
            </Tag>
            <Tag bordered={false} className=" text-primary font-medium">
              {expRange} Years
            </Tag>
          </div>
        </div>
        <div className="text-start">
          <button className="md:py-2 py-2 md:mt-0 mt-2 md:px-8 px-6 text-sm bg-primary text-white rounded-full text-center mx-auto w-full" onClick={showModal}>
            Apply
          </button>
        </div>
      </Space>
    </div>
  );
};

export default JobCard;
