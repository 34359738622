import React, { useEffect, useState } from "react";
import Navbar from "../homepage/components/Navbar";
import Footer from "../homepage/components/Footer";
import FilterComponent from "../globalComponents/FilterComponent";
import ListHero from "./components/ListHero";
import DemandingJobs from "../homepage/components/DemandingJobs";
import { getJobList } from "../../helpers/JobApi";
import { useLocation, useNavigate } from "react-router-dom";
import JobListing from "./components/JobListing";
import { Helmet } from "react-helmet";

const JobListPage = () => {
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [searchValue, setSearchValue] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const search = urlParams.get("search");
    setSearchValue(search);
    getJobListFunc({ search: search });
  }, [location]);

  const getJobListFunc = async (filter) => {
    if (filter) {
      try {
        let params = {};
        const searchText = filter?.search;
        if (searchText) params["search"] = searchText;
        setLoading(true);
        const jobListResult = await getJobList(params);
        setJobList(jobListResult);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const jobListResult = await getJobList();
        setJobList(jobListResult);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchInput = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
  };

  const handleSearchButton = () => {
    if (searchValue) navigate(`/joblist?search=${searchValue}`);
    else navigate(`/joblist`);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Listings - Dubai Job Finder</title>
        <link rel="canonical" href="https://dubaijobfinder.net/joblist" />
        <meta name="description" content="Discover a variety of job opportunities in Dubai at top companies. Search for positions that match your skills and career goals across diverse industries. Start your next professional journey in Dubai today with our comprehensive job listings." />
        <meta
          name="keywords"
          content="Dubai jobs, Job openings in Dubai, Dubai job vacancies, Dubai employment opportunities, Jobs in Dubai, Dubai careers, Dubai job search, Dubai job listings, Dubai job portal, Dubai job site, Dubai job board, Hiring in Dubai, Work in Dubai, Dubai professional jobs, Dubai job market, Dubai job fair, Dubai job recruitment, Dubai job sectors, Dubai job industries, Dubai job opportunities"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Dubai Job Finder" />
        <meta property="og:title" content="Job Listings" />
        <meta property="og:description" content="Discover a variety of job opportunities in Dubai at top companies. Search for positions that match your skills and career goals across diverse industries. Start your next professional journey in Dubai today with our comprehensive job listings." />
        <meta property="og:url" content="https://dubaijobfinder.net/joblist" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://dubaijobfinder.net/ogImage.png" />
      </Helmet>

      <Navbar />
      <div id="hero">
        <ListHero />
      </div>
      <div className="2xl:px-48 xl:px-40 lg:px-40 md:px-20 sm:px-6 px-6 container mx-auto mt-14">
        <div className="space-y-10">
          <div className="mt-4 md:flex md:flex-row gap-4">
            <div className="w-full">
              <input
                className="rounded-full text-start md:py-3.5 py-3 text-lg md:pr-20 pr-16 pl-6 w-full bg-opacity-10 bg-primary border-2 "
                placeholder="Search job, title, etc..."
                value={searchValue}
                onChange={handleSearchInput}
                onKeyDown={(event) => event.key === "Enter" && handleSearchButton()}
              />
            </div>
            <div className=" md:mr-auto text-end">
              <button className="md:py-4 py-3 md:mt-0 mt-4 md:px-10 px-8 text-lg bg-primary text-white rounded-full text-center mx-auto" onClick={handleSearchButton} type="submit">
                Search
              </button>
            </div>
          </div>
          <div id="filter">
            <FilterComponent setJobList={(val) => setJobList(val)} setLoading={(val) => setLoading(val)} />
          </div>
          <div id="list-jobs">
            <JobListing jobList={jobList} loading={loading} />
          </div>
        </div>
      </div>

      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default JobListPage;
