import { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const confirmModalRef = createRef();

const ConfirmModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(() => {});
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    open: (desc, title, onOk) => {
      setDesc(desc);
      setTitle(title);
      setOpen(true);
      setConfirm(() => onOk);
    },
    close: handleClose,
  }));

  return (
    <Modal
      title={
        <div className="space-y-4 flex justify-start flex-col items-center mx-auto">
          <ExclamationCircleFilled className="text-green-600 text-6xl" />
          <span className="text-xl font-manrope font-extrabold ">{title}</span>
        </div>
      }
      open={open}
      onCancel={handleClose}
      // onOk={() => {
      //   confirm();
      //   handleClose();
      // }}
      // onCancel={handleClose}
      style={{
        padding: "18px",
      }}
      footer={[
        <div className="text-center ">
          <Button type="primary" className="bg-primary hover:bg-dark" size="large" onClick={confirm}>
            Explore more jobs!
          </Button>
        </div>,
      ]}
    >
      <div className="text-center pb-8">
        <span className="text-base text-center font-manrope">{desc}</span>
      </div>
    </Modal>
  );
});

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
