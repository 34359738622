import { Space } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import React from "react";

const DescContent = ({ overview, desc, responsibilities, skills, benefits, extra }) => {
  return (
    <div>
      <Space direction="vertical" className="gap-8">
        <div>
          <Space direction="vertical">
            <span className="font-bold text-xl">Overview: </span>
            <div className="editor" dangerouslySetInnerHTML={{ __html: overview || "" }} />
          </Space>
        </div>
        <div>
          <Space direction="vertical">
            <span className="font-bold text-xl">Job Description: </span>
            <div className="editor" dangerouslySetInnerHTML={{ __html: desc || "" }} />
            {/* <span>{desc}</span> */}
          </Space>
        </div>
        <div>
          <Space direction="vertical">
            <span className="font-bold text-xl">Responsibilities: </span>
            <Space direction="vertical" className="gap-4">
              {responsibilities?.map((element, index) => (
                <span key={index}>
                  <CheckOutlined /> {element}
                </span>
              ))}
            </Space>
          </Space>
        </div>
        <div>
          <Space direction="vertical">
            <span className="font-bold text-xl">Required Skills: </span>
            <Space direction="vertical" className="gap-4">
              {skills?.map((element, index) => (
                <span key={index}>
                  <CheckOutlined /> {element}
                </span>
              ))}
            </Space>
          </Space>
        </div>
        <div>
          <Space direction="vertical">
            <span className="font-bold text-xl">Benefits: </span>
            <Space direction="vertical" className="gap-4">
              {benefits?.map((element, index) => (
                <span key={index}>
                  <CheckOutlined /> {element}
                </span>
              ))}
            </Space>
          </Space>
        </div>
        <div>
          <Space direction="vertical">
            <span>{extra && extra}</span>
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default DescContent;
