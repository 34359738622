import React, { useState } from "react";
import countryWithFlags from "../../../utils/countryWithFlags.json";
import ApplicationFormModal from "../../globalComponents/ApplicationFormModal";
import { API_URL, APP_JOB_LOGO_URL } from "../../../helpers/AppApiUrls";
import { confirmModalRef } from "../../globalComponents/ConfirmModal";

const DescCard = ({ amount, showModal, jobType, expRange, companyName, location, gender, jobId, countryId, logo_name, nationalityIds }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ApplicationFormModal open={open} setOpen={(val) => setOpen(val)} jobId={jobId} />
      <div className="flex justify-center items-center mx-auto">
        <div className="rounded-3xl sm:px-10 px-4 py-6 mx-auto justify-start xs:w-[350px] w-full h-fit bg-primary bg-opacity-10">
          <div className="flex justify-center items-center mx-auto flex-col xs:py-8 py-2 w-full">
            <img src={`${API_URL}${APP_JOB_LOGO_URL}/${logo_name}`} alt="logo" className="w-28 h-28 mb-3 rounded-full border object-scale-down max-h-full m-auto p-2 bg-white" />
            <span className="font-bold text-xl w-full text-center">{companyName}</span>
          </div>
          {/* <div className="flow-root pt-2">
            <div className="float-left space-y-4">
              <div>
                <span className="text-gray-400">Salary</span>
                <div className="font-bold">
                  <span>
                    {amount}
                    <span className="ml-1">AED</span>
                  </span>
                </div>
              </div>
              <div>
                <span className="text-gray-400">Experience</span>
                <div className="font-bold">
                  <span>{expRange} years</span>
                </div>
              </div>
              <div>
                <span className="text-gray-400">Location</span>
                <div className="font-bold">
                  <span>{location}</span>
                </div>
              </div>
            </div>
            <div className="float-right space-y-4">
              <div>
                <span className="text-gray-400">Job Type</span>
                <div className="font-bold">
                  <span className="capitalize">{jobType}</span>
                </div>
              </div>
              <div>
                <span className="text-gray-400">Nationality</span>
                <div className="font-bold flex gap-1">
                  {nationalityIds?.split(",")?.map((nationality) => (
                    <img key={nationality} src={countryWithFlags?.find((element) => element.countryId === Number(nationality))?.flag} alt="flag" width={25} />
                  ))}
                </div>
              </div>
              <div>
                <span className="text-gray-400">Gender</span>
                <div className="font-bold">
                  <span className="capitalize">{gender}</span>
                </div>
              </div>
            </div>
          </div> */}
          <div className="flex flex-row pt-2 gap-2 justify-between">
            <div className="float-left space-y-4">
              <div>
                <span className="text-gray-400">Salary</span>
                <div className="font-bold">
                  <span className="text-sm">
                    {amount}
                    <span className="ml-1">AED</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="float-right space-y-4">
              <div className="text-end">
                <span className="text-gray-400">Job Type</span>
                <div className="font-bold text-end">
                  <span className="capitalize text-sm">{jobType}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row pt-2 gap-2 justify-between">
            <div className="float-left flex-1 space-y-4">
              <div>
                <span className="text-gray-400">Experience</span>
                <div className="font-bold">
                  <span className=" text-sm">{expRange} years</span>
                </div>
              </div>
            </div>
            <div className="float-right space-y-4">
              <div className="text-end">
                <span className="text-gray-400">Nationality</span>
                <div className="font-bold flex gap-1 flex-wrap max-w-28 justify-end">
                  {nationalityIds?.split(",")?.map((nationality) => (
                    <img key={nationality} src={countryWithFlags?.find((element) => element.countryId === Number(nationality))?.flag} alt="flag" width={25} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row pt-2 gap-2 justify-between">
            <div className="float-left space-y-4">
              <div>
                <span className="text-gray-400">Job Location</span>
                <div className="font-bold text-start">
                  <span className=" text-sm">{location}</span>
                </div>
              </div>
              {/* <div>
                <span className="text-gray-400">Location</span>
                <div className="font-bold">
                  <span>{location}</span>
                </div>
              </div> */}
            </div>
            <div className="float-right space-y-4">
              <div>
                <span className="text-gray-400">Gender</span>
                <div className="font-bold text-end">
                  <span className="capitalize  text-sm">{gender}</span>
                </div>
              </div>
              {/* <div>
                <span className="text-gray-400">Gender</span>
                <div className="font-bold">
                  <span className="capitalize">{gender}</span>
                </div>
              </div> */}
            </div>
          </div>
          <div className="text-start mt-8">
            <button
              className="md:py-3 py-3 md:mt-0 mt-2 md:px-8 px-6 text-lg bg-primary text-white rounded-full text-center mx-auto w-full"
              onClick={() => {
                setOpen(true);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescCard;
