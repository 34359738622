export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function convertUTCtoIST(utcDateString) {
  // Parse the UTC date string to a Date object
  const date = new Date(utcDateString);

  // Convert UTC date to IST by adding 5 hours and 30 minutes
  // 5 hours = 5 * 60 minutes, 30 minutes, total minutes = 330 minutes
  date.setMinutes(date.getMinutes() + 330);

  // Format the date to a readable string in IST timezone
  // This will use the system's locale to format the date, make sure your system locale is set correctly
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric", // 2024
    day: "numeric", // 2, 3, etc.
    month: "long", // June, July, etc.
    timeZone: "Asia/Kolkata",
  });

  return formattedDate;
}
