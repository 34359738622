import React, { useEffect, useState } from "react";
import JobCard from "../../globalComponents/JobCard";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import noResults from "../../../data/assets/no_results.png";
import "./DemandingJobs.css";

const DemandingJobs = ({ jobList, loading }) => {
  return (
    <div className="bg-white w-full mx-auto text-center justify-center">
      <Spin
        spinning={loading}
        fullscreen
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 36,
            }}
            spin
          />
        }
      />
      <div className="w-full flex items-center mx-auto justify-center mt-8 sm:mb-32 mb-10">
        {jobList?.length !== 0 ? (
          <div className="gap-x-0 gap-y-6 grid 2xl:grid-cols-3 grid-cols-1 lg:grid-cols-2 w-full">
            {jobList
              ?.filter((element, index) => index < 9)
              .map((element, index) => (
                <JobCard key={index} amount={element?.salary_range} logo_name={element?.company_logo_name} job_id={element?.job_Id} title={element?.job_name} views={element?.view_count} jobType={element?.job_type_name} expRange={element?.experience_range} highlighting={element?.highlighting} />
              ))}
          </div>
        ) : (
          <div className="w-full justify-center items-center flex flex-col text-start">
            <span className="text-start font-semibold text-gray-400 text-lg font-manrope">Sorry, No results were found...</span>
            <img src={noResults} width={400} alt="no-result"></img>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemandingJobs;
