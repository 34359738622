import React from "react";
import UserFlowCard from "./subComponents/UserFlowCard";

const Userflow = () => {
  return (
    <div className="bg-white w-full mx-auto text-center justify-center">
      <div className="text-6xl mt-14 font-extrabold">How it Works?</div>
      <div className="w-full flex items-center mx-auto justify-center mt-8 sm:mb-32 mb-10">
        <div className="gap-4 grid sm:grid-cols-3 grid-cols-1 w-full">
          <UserFlowCard flowNumber={1} title="Check Trending Jobs" description="Find latest job trends, in-demand skills and more." />
          <UserFlowCard flowNumber={2} title="Analyse your Choices" description="Evaluate and compare jobs based on personal preferences." />
          <UserFlowCard flowNumber={3} title="Find your Dream Job" description="Find opportunities tailored to your skills, interests, and career goals." />
        </div>
      </div>
    </div>
  );
};

export default Userflow;
