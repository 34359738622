import React from "react";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <div>
      <Helmet>
        <title>Page Not Found - Your Website Name</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>404 - Page Not Found</h1>
      <p>We're sorry, but the page you were looking for doesn't exist.</p>
      {/* Add a link to your homepage or another important page here */}
    </div>
  );
};

export default PageNotFound;
